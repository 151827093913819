<template>
  <div class="container py-5">
    <p class="fw-bold">IMCAS World Congress 2022</p>
    <p>時間：2022 06/03-05</p>
    <p>地點：PARIS</p>
    <p>
      Enjoying our time in Paris at the IMCAS . 
      <br>
       It is very good to see all of you here and connect over the importance of skin analysis in the consultation flow!
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_06_1.jpg"
    />
    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_06.jpg"
    />
    
  </div>
</template>
<script>
export default {};
</script>
